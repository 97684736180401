<template>
  <div class="h-100">
    <div class="overflow-scroll p-1" @scroll="handleScroll" ref="scrollable">
      <div class="d-flex align-items-center mb-1 flex-wrap flex-xl-nowrap">
        <div class="d-flex mb-1 mb-xl-0 w-100 align-items-center">
          <b-button variant="outline-secondary" class="size-button-center" @click="$emit('slide_window')">
             <span><feather-icon icon="MenuIcon"/></span>
          </b-button>
          
          <b-input-group class="ml-1 w-lg-auto">
            <b-input-group-prepend is-text>
              <feather-icon icon="SearchIcon"></feather-icon>
            </b-input-group-prepend>
            <b-form-input :placeholder="$t('ia.community.findPrompt')" class="input-search-history" v-model="query"></b-form-input>
            <b-input-group-append is-text class="cursor-pointer" v-if="query && query.length > 0" @click="query = ''">
              <feather-icon icon="XIcon"></feather-icon>
            </b-input-group-append>
          </b-input-group>
        </div>
        <b-button 
          variant="blue-button" 
          class="blue-button create-your-own-button ml-xl-1 h-100"
          @click="redirectCreate()"
        >
          {{ $t('ia.community.creteOwn') }}
        </b-button>
      </div>

      <div class="border-bottom py-2" v-if="!query">
        <h2>{{ $t('ia.community.popularThemes') }}</h2>
        <div class="grid-covers">
          <div 
            class="cover-term" 
            v-for="(image, index) in popular_themes.slice(0, 2)" 
            :key="index"
            @click="query = image.title"
          >
            <b-img :src="image.static_image" class="image-cover-term"></b-img>
          </div>
        </div>
      </div>

      <div class="border-bottom py-2 overflow-results">
        <h3>{{ $t('ia.community.iaCommunity') }}</h3>
        <p class="avenir-medium" v-if="query">{{ $t('ia.community.searchKey')}}: {{ query }}</p>
        <div class="grid-results" v-if="loaded_service" :key="update_image">
          <div 
            v-for="(image, index) in images_community" 
            :key="index" class="container-community-item" 
            @click="clickCardOpenModal(image, index)"
          >
            <b-img :src="image.images[0]" class="img-community" @error="errorLoad(index)"></b-img>
            <div class="hover-container-info">
              <div>
                <div class="d-flex justify-content-between container-top-hover">
                  <div>
                    <b-avatar class="mr-05" :src="getAssetUrl(image.user.avatar)"></b-avatar> <span>{{ image.user.first_name }}</span>
                  </div>
                  <div class="">
                    <b-dropdown
                      no-caret
                      right
                      variant="link"
                      class="avenir-medium"
                      id="dropdown-options-image"
                    > 
                      <template #button-content><b-icon icon="three-dots-vertical" variant="white"/></template> 
                      <b-dropdown-item @click="downloadFile(image.images[0])"><feather-icon icon="DownloadIcon"></feather-icon>  Download Image</b-dropdown-item>
                    </b-dropdown>
                  </div>
                </div>
              </div>
              <span class="container-bottom-hover">{{ image.processed_prompt.substring(0, 200) }}
              {{ image.processed_prompt.length > 200  ? '...' : ''}}
              </span>
            </div>
          </div>
        </div>
        <div v-else class="container-loader-grid-results">
          <b-spinner class="spinner"></b-spinner>
        </div>
      </div>
    </div>

    <b-modal v-model="show_modal" hide-footer hide-header centered id="modal-backdrop-swiper" size="lg">
      <div class="container-modal-extra-info row" v-if="Object.keys(image_selected).length > 0">
        <b-img :src="image_selected.images[0]" class="image-selected col-12 col-lg-6 p-0"></b-img>
        <div class="col-12 col-lg-6 p-2">
          <div class="border-bottom pb-2">
            <div class="mb-2">
              <b-avatar class="avatar" :src="getAssetUrl(image_selected.user.avatar)"></b-avatar> <span>{{ image_selected.user.first_name }}</span>
            </div>
            <h2 class="mb-2">{{ image_selected.original_prompt }}</h2>
            <div>
              <p class="text-muted avenir-medium">PROMPT</p>
              <p>{{ image_selected.processed_prompt }}</p>
            </div>
            <b-button variant="outline-secondary" @click="downloadFile(image_selected.images[0])">Download</b-button>
          </div>
          <div class="pt-2 row">
            <div class="col-6 mb-1">
              <span class="d-block avenir-medium">MODEL</span>
              <span class="d-block">{{ image_selected.data.model ? $t('search.ia_options').find((i) => i.value === image_selected.data.model).label : '--'}}</span>
            </div>


            <div class="row m-0 w-100">
              <div class="col-6">
                <span class="d-block avenir-medium">SIZE</span>
                <span class="d-block">{{ image_selected.size_images }}</span>
              </div>
              <div class="col-6">
                <span class="d-block avenir-medium">NEGATIVE PROMPT</span>
                <span class="d-block"> {{ image_selected.data.negative_prompt ? image_selected.data.negative_prompt : '--' }}</span>
              </div>
            </div>
          </div>
        </div>
        <feather-icon
          icon="XIcon"
          size="24"
          class="close-icon-modal"
          @click="show_modal = !show_modal"
        />
      </div>
    </b-modal>
  </div>
</template>
    
<script>

import {
  BSpinner,
  BImg,
  BButton,
  BFormInput,
  BInputGroup,
  BInputGroupPrepend,
  BInputGroupAppend,
  BAvatar,
  BDropdown,
  BDropdownItem,
  BModal,
} from 'bootstrap-vue';
import config from '@/services/config';
import { getCommunityImages, getPopularThemes } from '@/services/ia'
import { getAssetUrl } from '@/libs/utils/urls'
    
export default {
  name: 'communityGrid',
  components: {
    BSpinner,
    BImg,
    BButton,
    BFormInput,
    BInputGroup,
    BInputGroupPrepend,
    BInputGroupAppend,
    BAvatar,
    BDropdown,
    BDropdownItem,
    BModal,
  },
  data() {
    return {
      getAssetUrl,
      axios: null,
      cancel_search_ws: null,
      query: undefined,
      loaded_service: false,
      images_community: [],
      update_image: false,
      static_images: [],
      popular_themes: [],
      show_modal: false,
      image_selected: {},
    }
  },
  props: {
    category_selected: {
      type: Object,
      default: () => {
        return {}
      },
    }
  },
  async mounted() {
    this.axios = (await import('axios')).default;
  },
  created() {
    this.getCommunityImages();
    this.getPopularThemes();

  },
  methods: {
    handleScroll() {
      const scroll = this.$refs.scrollable.offsetHeight + this.$refs.scrollable.scrollTop
      if (scroll >= this.$refs.scrollable.scrollHeight) {
        this.getCommunityImages();
      }
    },
    clickCardOpenModal(image, index) {
      this.image_selected = image;
      this.image_selected['static_image'] = this.static_images[index];
      this.show_modal = true; 
    },
    getPopularThemes() {
      getPopularThemes().then((response) => {
        this.popular_themes = response.results;
        this.popular_themes.map((i) => {
          i['static_image'] = i.images[Math.floor(Math.random() * i.images.length)]
        })
      })
    },
    downloadFile(url) {
      const xhr = new XMLHttpRequest();
      xhr.open('GET', url, true);
      xhr.responseType = 'blob';
      xhr.onload = () => {
        if (xhr.status === 200) {
          const blob = xhr.response;
          const link = document.createElement('a');
          link.href = window.URL.createObjectURL(blob);
          const splitted = url.split('/');
          link.download = splitted[splitted.length - 1];
          link.click();
        }
      };
      xhr.onerror = () => {
        if (xhr.status === 0)
          window.open(url, '_blank');
      };
      xhr.send();
    },
    getCommunityImages() {
      const data = {
        number: 12,
        prev_images: this.images_community.map((i) => i.uuid),
        query: this.query && this.query.length > 0 ? this.query : undefined
      }
      getCommunityImages(data).then((response) => {
        this.setFormatData(response)
      });
    },
    setFormatData(response) {
      this.images_community = this.images_community.concat(response.results);
      setTimeout(() => {
        this.loaded_service = true;
      }, 20)
    },
    redirectCreate() {
      this.$router.push({ name: 'brandme_AI', params: {section:'image', category: 'txt_to_image'}}).catch(() => {})
      this.category_selected.section = 'image';
      this.category_selected.category = 'txt_to_image';
    },
    errorLoad(index) {
      this.images_community[index].images[0] = require("@/assets/images/dashboard/imgDefault.png");
      this.update_image = !this.update_image;
    },
    searchQuery(query) {
      const CancelToken = this.axios.CancelToken;
      if (this.cancel_search_ws) this.cancel_search_ws()
      const self = this;
      const data = {
        query,
        number: 12
      }
      this.axios({
        method: 'post',
        url: `${config.urlCore}/api/v3/ai/community-images/`,
        data,
        headers: { Authorization: `Bearer ${localStorage.getItem('accessToken')}` },
        cancelToken: new CancelToken(function executor(c) {
          self.cancel_search_ws = c;
        })
      }).then((response) => {
        this.images_community = [];
        this.setFormatData(response.data.response)
      })
    },
  },
  watch: {
    query(val) {
      if (val.length === 0) {
        this.getCommunityImages();
      } else this.searchQuery(val)
    }
  }
}
</script>
<style lang="scss" scoped>
.overflow-scroll {
  overflow-y: auto;
  overflow-x: hidden;
  height: 100%;
  .mr-05 {
    margin-right: 0.5em;
  }
  .create-your-own-button {
    flex-shrink: 0;
    width: 100%;
    
    @media(min-width: 1200px) {
      width: auto;
    }
  }
  .grid-covers {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 2em;
    grid-auto-flow: dense;
    .cover-term {
    
      aspect-ratio:  100% / 200;
      border-radius: 1em;
      font-size: 3em;
      color: white;
      display: flex;
      justify-content: center;
      align-items: center;
      min-height: 100px;
      max-height: 200px;
      cursor: pointer;
      overflow: hidden;

      .image-cover-term {
        width: 100%;
        height: 200px;
        object-fit: cover;
      }

      @media(max-width: 430px) {
        width: 100%;
        aspect-ratio: 0 / 0;
      }
    }
    @media(max-width: 800px) {
      grid-template-columns: repeat(1, 1fr);
    }
  }
  .grid-results {
    display: grid;
    width: 100%;
    grid-template-columns: repeat(4, 1fr);
    grid-gap: 15px;

    .container-community-item  {
      position: relative;
      overflow: hidden;
      border-radius: 1em;
      cursor: pointer;
      .img-community {
        width: 100%;
        height: 300px;
        object-fit: cover;
      }
      .hover-container-info {
        position: absolute;
        background-color: rgba(0, 0, 0, 0.644);
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        padding: 1em;
        color: white;
        opacity: 0;
        transition: all 300ms;
        display: flex;
        flex-direction: column;
        justify-content: space-between;

        .container-top-hover {
          transform: translateY(-50px);
          transition: all 300ms;
        }
        .container-bottom-hover {
          transform: translateY(100px);
          transition: all 300ms;

        }
      }

      &:hover {
        .hover-container-info {
          opacity: 1;
        }
        .container-top-hover {
          transform: translateY(0px);
        }
        .container-bottom-hover {
          transform: translateY(0px);
        }
      }

    }
    
    @media(max-width: 1600px) {
      grid-template-columns: repeat(4, 1fr);
    }
    @media(max-width: 1300px) {
      grid-template-columns: repeat(3, 1fr);
    }
    @media(max-width: 950px) {
      grid-template-columns: repeat(2, 1fr);
    }
    @media(max-width: 650px) {
      grid-template-columns: repeat(1, 1fr);
    }
  }
  .size-button-center {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 1rem;
    height: 3rem;
  }
  .input-search-history {
    height: 3rem;
  }
  .container-loader-grid-results {
    width: 100;
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 300px;
    .spinner {
      height: 6em;
      width: 6em;
      color: #acacac;
    }
  }
}
.container-modal-extra-info {
  background-color: white;
  padding: 1.2em;
  border-radius: 1em;
  .image-selected {
    width: 100%;
    object-fit: cover;
    border-radius: 0.8em;
  }
  .avatar {
    width: 40px;
    height: 40px;
    margin-right: 0.7em;
  }
  .close-icon-modal {
    position: absolute;
    top: 2em;
    right: 2em;
    color: #979797;
    cursor: pointer;
  }
}
</style>
<style lang="scss">
#dropdown-options-image {
  .btn {
    padding: 0.7em !important;
  } 
}
#modal-backdrop-swiper {
  background-color: rgba(255, 255, 255, 0.1);
  backdrop-filter: blur(10px);
  opacity: 9;
  .modal-content {
    border-radius: 2em;
    background-color: transparent;
    box-shadow: none;
  }
}

</style>
